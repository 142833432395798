jQuery(document).ready(function ($) {

    /**
     * Dispara determinadas funções quando uma ação for realizada no elemento definido
     */
    $(document).on('change', '#product-tipoProduto', function () {
        var element = $(this),
            dynamicContainer = $('#dynamicVariations');
        // Exibe o valor das variações ao selecionar o tipo de produto
        show_price_from_variation(element);
        // Exibe os itens inclusos no curso
        show_included_items()
        // Inicia as datas ao carregar a páginas
        init(element);
        // Dispara a requisição ajax
        searchAction(element, dynamicContainer);
    });

    $(document).on('change', '#product-online-ao-vivo', function () {
        var element = $(this),
            dynamicContainer = $('#dynamic-online-ao-vivo');
        // Exibe o valor das variações ao selecionar o tipo de produto
        show_price_from_variation(element);
        // Inicia as datas ao carregar a páginas
        init(element);
        // Dispara a requisição ajax
        searchAction(element, dynamicContainer);
    });

    $(document).on('change', '#product-online', function () {
        var element = $(this),
            dynamicContainer = $('#dynamic-online');
        // Exibe o valor das variações ao selecionar o tipo de produto
        show_price_from_variation(element);
        // Inicia as datas ao carregar a páginas
        init(element);
        // Dispara a requisição ajax
        searchAction(element, dynamicContainer);
    });

    $(document).on('change', '#product-presencial', function () {
        var element = $(this),
            dynamicContainer = $('#dynamic-presencial');
        // Exibe o valor das variações ao selecionar o tipo de produto
        show_price_from_variation(element);
        // Inicia as datas ao carregar a páginas
        init(element);
        // Dispara a requisição ajax
        searchAction(element, dynamicContainer);
    });

    /**
     * Dispara a seleção do tipo de produto quando a página for carregada no MOBILE
     */
    if ($(window).width() <= 992) {
        $('#product-tipoProduto').trigger('change');
    }

    /**
     *  Dispara a seleção do tipo de produto quando a página for carregada no DESKTOP
     */
    if ($(window).width() > 992) {
        $('#product-online').trigger('change');
        $('#product-online-ao-vivo').trigger('change');
        $('#product-presencial').trigger('change');
    }

    /**
     * Run necessary function to init the select fields.
     *
     * @param element
     */
    function init(element) {
        var variations = element.find('option:selected').data('variations-id');

        // Recebe as datas de cada variação
        show_dates_from_variations(variations);
        $('#product-date').removeAttr('disabled');

        // accordions_full_width(); // A priori, função esta em desuso no novo layout. Retirar após validações
    }

    /**
     *
     * @param variations
     */
    function show_dates_from_variations(variations) {
        // Loop trough all date options
        $('#product-date').children().each(function () {

            var _this = this;
            var output = 0;

            variations.forEach(function (variation_id, i) {
                if ($(_this).data('variation') === variation_id) {
                    output += 1;
                }
            });

            if (output > 0) {
                $(this).removeAttr('disabled'); // Select first date
                $(this).show();
            } else {
                $(this).attr('disabled', 'disabled');
                $(this).hide();
            }
        }); // Select first option not disabled

        var $firstOptionValue = $('#product-date option:not([disabled]):first').val();

        if ($firstOptionValue) {
            $('#product-date').val($firstOptionValue);
            show_price_from_variation($firstOptionValue);
        }
    }

    /**
     * Retorna o valor da variação selecionada.
     * @param variation_id
     */
    function show_price_from_variation(element, variation_id) {

        // Define um ID para variação, quando esta chega indefinida
        if (typeof variation_id != 'string' &&
            typeof variation_id != 'number') {
            variation_id = element.find(':selected').val();
        }

        // Oculta os valores da seleção anterior e
        // Exibe os valores da seleção atual no MOBILE
        if ($(window).width() <= 992) {
            $(document).find('.product-price').hide();
            $(document).find('.cashback-price').hide();
            $(document).find('.product-installment-price').hide();
            $(document).find('#m-product-price-' + variation_id).show();
            $(document).find('#m-product-installment-price-' + variation_id).show();
            $(document).find('#m-cashback-' + variation_id).show();
        }

        // Oculta os valores da seleção anterior e
        // Exibe os valores da seleção atual no DESKTOP
        if ($(window).width() > 992) {
            element.closest('form').find('.product-price').hide();
            element.closest('form').find('.cashback-price').hide();
            element.closest('form').find('.product-installment-price').hide();
            element.closest('form').find('#product-price-' + variation_id).show();
            element.closest('form').find('#product-installment-price-' + variation_id).show();
            element.closest('form').find('#cashback-' + variation_id).show();
        }
    }

    function show_included_items() {
        var element  = $('#product-tipoProduto')
        toggleId = element.find('option:selected').data('toggle-fields');

        $('.included-itens-list').hide()
        $('#included-itens-list-' + toggleId).show()
    }

    /**
     * Prepara os dados que serão usados na requisição AJAX
     *
     * @param element
     * @param dynamicContainer
     */
    function searchAction(element, dynamicContainer) {
        var currentOpt   = $('option:selected', element),
            productId    = element.attr('data-product-id'),
            toggle       = currentOpt.attr('data-toggle-fields'),
            variations   = currentOpt.attr('data-variations-id'),
            variation_id = currentOpt.val();

        fetchResults(
            element,
            dynamicContainer,
            variations,
            toggle,
            productId,
            variation_id
        );
    }

    /**
     * Requisição AJAX
     *
     * @param element
     * @param dynamicContainer
     * @param searchValue
     * @param toggle
     * @param productId
     * @param variationId
     */
    function fetchResults(
        element,
        dynamicContainer,
        searchValue,
        toggle,
        productId,
        variationId,
    ) {
        var container   = dynamicContainer,
            selectField = element,
            data = {
                action: tipoProduto.action,
                nonce:  tipoProduto.nonce,
                search: searchValue,
                toggle: toggle,
                productId: productId,
                variationId: variationId,
            };

        $.ajax({
            url: tipoProduto.ajax_url,
            type: 'POST',
            data: data,
            beforeSend: function beforeSend() {
                container.empty();
            },
            success: function success(result) {
                selectField.removeAttr('disabled');
                container.append(result);
                $('#product-place :selected, #product-date :selected').trigger('change');
            },
            error: function error(errorThrown) {
                console.log(errorThrown);
            },
        });
    }

    /**
     * Trigger the progress bar animation [LEGADO]
     */
    function progress_bar_animation() {
        var bar = $('.progress-bar');
        var max = bar.attr('aria-valuemax');
        var current = bar.attr('aria-valuenow');
        width = current * 100 / max;
        bar.css('width', width + '%');
    }

    /**
     * Update progress bar with selected date stock. [LEGADO]
     */
    function update_progress_bar(elm) {
        var stock = elm.find('#product-date > option:selected').data('stock');
        var max = elm.find('#product-date > option:selected').data('max');

        if (max / 2 > stock) {
            var bar = $('.progress-bar');
            bar.attr('aria-valuemax', max);
            bar.attr('aria-valuenow', stock); // Update

            $('.product-remaining-places').removeClass('d-none');
            $('.remaining-places .places').html(stock);
            progress_bar_animation();
        } else {
            $('.product-remaining-places').addClass('d-none');
        }
    }

    /*********************************************************
     * CÓD. LEGADO JÁ COMENTADO ANTERIORMENTE
     *********************************************************/
    // $('#product-place').change( update_dates_select );
    // $('#product-date').change( show_price_from_variation );
    //
    // // Progress bar
    // $('#product-place').change( update_progress_bar );
    // $('#product-date').change( update_progress_bar );
    // Trigger changes on load
    // $('.triggerPrice').each( function(i) {
    //   $('triggerPrice-' + i).on('change', show_price_from_variation)
    // })
    // $('select[name="tipoProduto"]').each( function () {
    //   $(this).trigger('change')
    // })

    /*********************************************************
     * CÓD. LEGADO - DESATIVADO PQ A PRINCÍPIO NÃO ESTA FUNCIONAL
     *********************************************************/
    // $(document).on('change', '#product-place', update_dates_select);
    // $(document).on('change', '#product-date, #product-place', update_progress_bar);
    // $(document).on('change', '#product-place', setCurrentTipoProduto);
    /* ************************************************************* */

    /**
     * Updates dates select in add-to-cart form.
     */
    //function update_dates_select() {
    //  var variations = $(this).find(':selected').data('variations');
    //  show_dates_from_variations(variations);
    //}

    //function setCurrentTipoProduto() {
    //  var variation_id = $(this).find(':selected').val();
    //  $('#product-tipoProduto option').each(function () {
    //    $(this).attr('selected', false);
    //    var optArray = $(this).attr('data-variations-id');
    //
    //    if (optArray.includes(variation_id)) {
    //      $(this).attr('selected', 'selected');
    //      $(this).val(variation_id);
    //    }
    //  });
    //}

    // function accordions_full_width() {
    //     if (!jQuery('.product-videos').length > 0 && !jQuery('.product-ebooks').length > 0 && jQuery('.product-tabs').length > 0) {
    //         jQuery('.product-tabs').addClass('tabs-full-width');
    //     }
    // }
    $('.card-dynamic').each(function(){
        //update_progress_bar($(this));
        progress_bar_animation();
    });

    $(document).on('change', '#product-date', function () {
        let elem = $(this);
        let valor = elem.val();
        let tipo_curso = '';

        if(elem.closest('.card-dynamic').length > 0){
            tipo_curso = elem.closest('.card-dynamic').attr('id').split('dynamic-')[1];
            $('.card-footer-'+tipo_curso).addClass('d-none');
        } else {
            tipo_curso = $('#product-tipoProduto').find('option:selected').data('toggle-fields');
            $('.card-footer').addClass('d-none');
        }
        
        setTimeout(function(){
            $('.card-footer-'+tipo_curso+'#ultimas-vagas-'+ valor).removeClass('d-none');
        }, 500);
    });

    $('#product-date').trigger('change');

    let menu_timeout;
    let menu_is_open = false;

    $('#menu-menu-superior .menu-item-has-children .dropdown-toggle').on('mouseenter', function(){
        stopTimeout();
        if(menu_is_open == false){
            $(this).trigger('click');
            menu_is_open = true;
        }
    });

    $('#menu-menu-superior .menu-item-has-children .dropdown-toggle').on('mouseleave', function(){
        menu_timeout = setTimeout(function(){
            $('#menu-menu-superior .menu-item-has-children .dropdown-toggle').trigger('click');
            menu_is_open = false;
        }, 2000)
    });

    $('#menu-menu-superior .menu-item-has-children .dropdown-menu').on('mouseenter', function(){
        stopTimeout();
    });

    $('#menu-menu-superior .menu-item-has-children .dropdown-menu').on('mouseleave', function(){
        menu_timeout = setTimeout(function(){
            $('#menu-menu-superior .menu-item-has-children .dropdown-toggle').trigger('click');
            menu_is_open = false;
        }, 2000)
    });

    $('.search-toggle').on('click', function(){
        stopTimeout();
        menu_is_open = false;
    });

    function stopTimeout() {
        clearTimeout(menu_timeout);
    }
});